// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-index-js": () => import("./../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-order-history-js": () => import("./../src/pages/account/order-history.js" /* webpackChunkName: "component---src-pages-account-order-history-js" */),
  "component---src-pages-cart-js": () => import("./../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-get-tested-js": () => import("./../src/pages/get-tested.js" /* webpackChunkName: "component---src-pages-get-tested-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-kit-js": () => import("./../src/pages/register-kit.js" /* webpackChunkName: "component---src-pages-register-kit-js" */),
  "component---src-templates-product-page-index-js": () => import("./../src/templates/ProductPage/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */)
}

